import { getMostRecentlyConnectedShopify } from '../utils/get-most-recent-connected-shopify';
import { useGetBrand } from './api/brand';

type ShopifyConnectionType = 'full' | 'n/a' | 'ro' | 'unknown' | undefined;

export function useBrandStatus() {
  const { data } = useGetBrand();

  const connectedShopify = getMostRecentlyConnectedShopify(
    data ? data.adapters : []
  );
  const isShopifyConnected = !!connectedShopify;
  const shopifyConnectionType: ShopifyConnectionType = connectedShopify
    ? (connectedShopify.state?.tier as ShopifyConnectionType)
    : undefined;

  // `/api/brand` will never return stores where `salesforce_store_stage === "SPACE_UNAVAILABLE" || "SPACE_PASS" || "POTENTIAL" || "CONTRACTING"`
  // but filtering those out anyway.
  const storesNotOnboardingCount = data?.stores
    ? data?.stores.filter(
        (store) =>
          store.salesforce_store_stage !== 'SPACE_UNAVAILABLE' &&
          store.salesforce_store_stage !== 'SPACE_PASS' &&
          store.salesforce_store_stage !== 'POTENTIAL' &&
          store.salesforce_store_stage !== 'CONTRACTING' &&
          store.salesforce_store_stage !== 'ONBOARDING'
      ).length
    : 0;

  const hasStoresNotOnboarding = storesNotOnboardingCount > 0;

  // `/api/brand` will never return stores where `salesforce_store_stage === "SPACE_UNAVAILABLE" || "SPACE_PASS" || "POTENTIAL" || "CONTRACTING"`
  // but filtering those out anyway.
  const activeStoresCount = data?.stores
    ? data.stores.filter(
        (store) =>
          store.salesforce_store_stage === 'ONBOARDING' ||
          store.salesforce_store_stage === 'LIVE' ||
          store.salesforce_store_stage === 'STORE_CLOSURE' ||
          store.salesforce_store_stage === 'STORE_CLOSED'
      ).length
    : 0;

  const showOmniChannelFitness = activeStoresCount === 0;

  return {
    connectedShopify,
    hasStoresNotOnboarding,
    isShopifyConnected,
    shopifyConnectionType,
    showOmniChannelFitness,
  };
}
